<template>
	<div class="orderItem" v-loading="getDelaystatus">
		<div v-if="value.length < 1" class="flex-column-center-center">
			<svg-icon icon-class="NoContent" />
			<!-- <div>暂无内容</div> -->
		</div>
		<div class="item" v-for="(item, index) in value" :key="index">
			<div class="item-title padding-lr-15 flex-row-space-between">
				<div class="flex-row-align-center">
					<el-checkbox v-if="isBatch || isDistributionBatch || RefundBatch" v-model="item.checked" @change="radioChange"></el-checkbox>
					<!-- 企业购 fTradingModelId == 2 -->
					<div v-if="item.fActivityID =='47'" class="newenterprise font-color-fff text-center font-size12 bg-40C6A9">秒杀</div>
					<div v-if="item.fTradingModelID=='2'" class="enterprise font-color-fff text-center font-size12 bg-40C6A9">企</div>
					<div v-if="item.fActivityID =='43'" class="newenterprise font-color-fff text-center font-size12 bg-40C6A9">任 务</div>
					<div v-if="item.fMonitorOrderID > 0" class="newenterprise font-color-fff text-center font-size12 bg-40C6A9">定 向</div>
					<div v-if="item.fTradingModelID=='5'" class="enterpriseBtn font-color-fff text-center font-size12 bg-40C6A9">积分购</div>
					<div class="orderID">
						订单号:&nbsp;<span class="color-theme">{{ item.fOrderNumber }}</span>
					</div>
					<div class="time">{{ item.fCreateTime | dateFormat }}</div>
					<div class="shopname flex-row-align-center">
						<i class="iconfont icon-dianpu color-theme"></i>
						<div class="shopname hide-text margin-l-5">{{ item.fShopName }}</div>
					</div>
				</div>
				<div class="flex-row">
					<div v-if="item.fEndDate != '' && item.fEndDate != null " class="endTime font-color-666">截止日期:&nbsp;{{item.fEndDate | formatDate}}</div>
					<!-- <div v-if="item.fEnableShareStatus == 1" @click="shareOrder" class="share pointer">
						<i class="iconfont icon-fenxiang font-color-ff6666"></i><span>分享订单</span>
					</div> -->
				</div>
			</div>
			<div class="item-content">
				<div class="flex-row-space-between">
					<div class="flex-row item-content-left">
						<div class="goods-pic flex-row-center-center text-center">
							<img :src="item.imageUrl" alt="" style="width: 55px;height: 55px;" />
						</div>
						<div>
							<div class="productionPlant">{{ item.fGoodsFullName }}</div>
							<div class="flex-row font-size12">
								<!-- 积分换购 fTradingModelId == 3 -->
								<div v-if="item.fTradingModelID==3" class="label-bg font-color-fff text-center margin-r-5 padding-lr-5">
									{{item.fTradingModel}}
								</div>
								<div v-if="item.fShopTypeID==1" class="label-bg font-color-fff text-center margin-r-5 padding-lr-5">
									{{item.fShopTypeName}}
								</div>
								<!-- 几倍积分待定 -->
								<!-- <div class="label font-color-fff text-center label-bg">
									{{ item.label }}
								</div> -->
								<div class="delivery color-theme border-color-theme text-center margin-r-10">
									{{ item.fDelivery }}
								</div>
								<div class="delivery color-theme border-color-theme text-center">
									{{ item.fBuTypeName }}
								</div>
							</div>
							<div class="engineering">工程:&nbsp;{{ item.fProjectName == "" ? "无" : item.fProjectName }}</div>
							<!-- 评价 是否已评价判断字段——待确定  暂时隐藏  -->
							<div class="evaluated flex-row-align-center" v-if="item.fEvaluationScore != 0">
								<div class="evaluated-text">已评价:</div>
								<div style="margin-bottom: -3px;">
									<el-rate v-model="item.fEvaluationScore/20" disabled text-color="#ff0000">
									</el-rate>
								</div>
							</div>
						</div>
					</div>
					<div class="item-content-right">
						<div class="num commonBox">
							x&nbsp;{{ item.fPlanAmount }}&nbsp;{{ item.fUnitName }}
						</div>
						<div class="payment commonBox font-color-FF0000" v-if="item.fTradingModelID==3 || item.fTradingModelID==5">
							{{ item.fUsePoint }}积分
						</div>
						<div  class="payment commonBox font-color-FF0000" v-else-if="(item.fTradingModelID!=3 && item.fTradingModelID!=35)  && getUserInfo.fShowPrice == 1 ">
							¥{{ item.fPlyingStatus == 0 ? item.fMoney : item.fGatheringMoney }}
						</div>
						<div class="payment commonBox font-color-FF0000" v-else>
							--
						</div>
						<div  class="payStatus commonBox" :class="item.fPlyingStatus == 0 ? 'font-color-FF0033' : 'color-theme'">
							{{ item.fBusinessStatusName }}
							<div v-if='item.fNegotiateStatus == 1'   style="color:#FF0000">(议价中)</div>
						</div>
						<div class="operation commonBox flex-column-start-center font-size13">
							<el-popover placement="right" width="233" trigger="click" v-if="item.fPlyingStatus > 2" @show="toRefundDetails(item)">
								<div class="padding-lr-13 background-color-theme flex-colum font-size13 margin-b-5">
									<div class="margin-b-5">申请时间：{{refundDetails.fCreateTime}}</div>
									<div class="padding-lr-5 font-size12 font-color-fff" :class="{'bg-FF0000':refundDetails.fStatus == 1,'bg-25a66e':refundDetails.fStatus == 2,'bg-666':refundDetails.fStatus == 3}"
									 style="width: 50px;">
										{{refundDetails.fStatusName}}
									</div>
								</div>
								<!-- <div class="padding-13 font-weight700 font-size14">
									退款明细
								</div> -->
								<div class="padding-lr-13 box bg-fff margin-b-5">实退金额：¥{{refundDetails.fRefundMoney}}</div>
								<div v-if="refundDetails.fIsShowFTMoney == 1" class="padding-lr-13 box bg-fff margin-b-5">实退货款金额：¥{{refundDetails.fRefundGoodsMoney}}</div>
								<div v-if="refundDetails.fIsShowFTMoney == 1" class="padding-lr-13 box bg-fff margin-b-5">实退运费金额：¥{{refundDetails.fRefundFMoney}}</div>
								<div class="padding-lr-13 box bg-fff margin-b-5">实退积分：{{refundDetails.fRefundPoint}}个</div>
								<div class="padding-lr-13 box bg-fff">{{refundDetails.fDispRefundPath}}</div>
								<button slot="reference" class="pointer background-color-theme">退款详情</button>
							</el-popover>
							<button v-if="item.fEnablePayStatus == 1 && item.fNegotiateStatus !=1" class="pointer background-color-theme" @click="toPayment(item)">去支付</button>
							<button v-if="item.fEnableSchedule == 1" class="pointer background-color-theme" @click="toSendCar(item)">去派车</button>
							<button v-if="item.fEnableReturnStatus == 1 && getUserInfo.fShowPrice == 1" class="pointer background-color-theme" @click="applyForRefund(item)">申请退款</button>
							<button v-if="item.fEnableDelayStatus == 1" class="pointer background-color-theme" @click="delay(item)">申请延期</button>
							<button v-if="item.fEnableEvaluateStatus == 1" class="pointer background-color-theme" @click="toEvaluate(item)">评价</button>
							<button v-if="item.fEnableDeleteStatus == 1" class="pointer border-color-theme" @click="toVoid(item)">作废</button>
							<button v-if="item.fEnableSchedule == 1 && getUserInfo.fUserID == item.fCreaterID" class="pointer background-color-theme"
							 @click="distributionClick(item)">订单分配</button>
							<button class="pointer border-color-theme" @click="toOrderDetails(item)">订单详情</button>
						</div>
					</div>
				</div>
				<div v-if="item.fPlyingStatus == 1" class="item-bottom flex-row">
					<div>订单量:&nbsp;{{ item.fPlanAmount.toFixed(2) }}</div>
					<div>已派车量:&nbsp;{{ item.fScheduleVanAmount.toFixed(2) }}</div>
					<div>提货量:&nbsp;{{ item.fReceivedAmount.toFixed(2) }}</div>
					<div>可派车量:&nbsp;{{ (item.fPlanAmount - item.fScheduleVanAmount).toFixed(2) }}</div>
					<div v-if="dp == '待派车'" >排队车次:&nbsp;{{ item.fLineUpQuantity }}</div>
				</div>
			</div>
		</div>
		<div v-if="isBatch" class="oder-item-btn-box flex-row-space-between-center padding-13">
			<el-checkbox v-model="allChecked" @change="allCheckChange">全选</el-checkbox>
			<div class="flex-row-align-center">
				<div @click="batchVoid" class="oder-item-btn pointer flex-row-center-center border-color-theme margin-r-10">批量作废</div>
				<div @click="batchPay" class="oder-item-btn pointer flex-row-center-center background-color-theme border-color-theme_2">批量支付</div>
			</div>
		</div>
		<div v-if="RefundBatch" class="oder-item-btn-box flex-row-space-between-center padding-13">
			<el-checkbox v-model="allChecked" @change="allCheckChange">全选</el-checkbox>
			<div class="flex-row-align-center">
				<!-- <div @click="batchVoid" class="oder-item-btn pointer flex-row-center-center border-color-theme margin-r-10">批量作废</div> -->
				<div @click="RefundClick" class="oder-item-btn pointer flex-row-center-center background-color-theme border-color-theme_2">批量退款</div>
			</div>
		</div>
		<div v-if="isDistributionBatch" class="oder-item-btn-box flex-row-space-between-center padding-13">
			<el-checkbox v-model="allChecked" @change="allCheckChange">全选</el-checkbox>
			<div class="flex-row-align-center">
				<div @click="batchDistribution" class="oder-item-btn pointer flex-row-center-center background-color-theme border-color-theme_2">确定</div>
			</div>
		</div>
		<el-dialog v-dialogDrag v-if="customerDialog" title="请选择用户" :visible.sync="customerDialog" width="600px"
		 :close-on-press-escape="false" :close-on-click-modal="false">
			<el-table :data="customerData" @selection-change="handleSelectionChange" @select-all="handleSelectionAll" border
			 style="width: 100%; margin-top:5px;" height="500px" ref="multipleTable">
				<el-table-column type="selection" fixed width="50" align="center" />
				<el-table-column prop="fUserName" label="用户名"></el-table-column>
				<el-table-column prop="fRoles" label="角色"></el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="customerDialog = false" class="table-btn" style="">取 消</el-button>
				<el-button @click="chooseConfirm" class="table-btn background-color-theme">确 定</el-button>
			</span>
		</el-dialog>
		<!--批量退款时，不能退款的理由 -->
		<el-dialog v-dialogDrag v-if="manyDialog" title="查看不能退款的原因" :visible.sync="manyDialog" width="925px"
		 :close-on-press-escape="false" :close-on-click-modal="false">
			<el-table :data="objList" border
			 style="width: 100%; margin-top:5px; max-height: 500px;" ref="multipleTable">
				<el-table-column prop="fOrderNumber" label="订单号"></el-table-column>
				<el-table-column prop="fGoodsFullName" label="商品"></el-table-column>
				<el-table-column prop="message" label="意见"></el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="manyDialog = false" class="table-btn" style="">取 消</el-button>
				<!-- <el-button @click="chooseConfirm" class="table-btn background-color-theme">确 定</el-button> -->
			</span>
		</el-dialog>
		<!-- 批量退款时，不能退款的理由 -->
		<!-- 不能退款后作废派车单 -->
		<el-dialog v-loading="showloading" v-dialogDrag v-if="showDeliveryOrderList" title="不可退款的派车单" :visible.sync="showDeliveryOrderList" width="80%"
		 :close-on-press-escape="false" :close-on-click-modal="false">
			<el-table v-loading="showList" :data="DeliveryOrderList" border  :row-class-name="tableRowClassName" height="500" :header-cell-style="{ 'text-align': 'center', background: '#F7F9FE' }"
			 style="width: 100%; margin-top:5px;overflow: auto;" ref="multipleTable">
				<el-table-column type="index" width="50" align="center" />
				<el-table-column width="120" prop="fDeliveryOrderNumber" label="派车单号"></el-table-column>
				<el-table-column width="100" align="center" prop="fStatusName" label="状态"></el-table-column>
				<el-table-column width="80" align="center" prop="fIsDeleteName" label="作废状态"></el-table-column>
				<el-table-column width="260" prop="fGoodsFullName" label="商品"></el-table-column>
				<el-table-column width="160" prop="fDepotName" label="仓库"></el-table-column>
				<el-table-column width="80" align="center" prop="ScheduleVanAmount" label="派车量"></el-table-column>
				<el-table-column width="160" prop="fProjectName" label="工程"></el-table-column>
				<el-table-column prop="fDeliveryAddress" label="收货地址"></el-table-column>
				<el-table-column
				  label="操作"
				  width="80">
				  <template slot-scope="scope">
				    <el-button v-if="scope.row.fEnableDeleteStatus == 1 && scope.row.fIsDelete == 0" type="danger" size="small" @click="deleteOrder(scope.row)">作废</el-button>
				  </template>
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showDeliveryOrderList = false" class="table-btn" style="">取 消</el-button>
				<el-button @click="gorefund" :loading="showloading" class="table-btn background-color-theme">继续退款</el-button>
			</span>
		</el-dialog>
		<!-- 不能退款后作废派车单 -->
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		name: "orderItem",
		components: {},
		props: {
			value: {
				type: Array,
				default: [],
			},
			isBatch: {
				type: Boolean,
				default: false
			},
			isDistributionBatch: {
				type: Boolean,
				default: false
			},
			dp:{
				type:String,
				default:''
			},
			RefundBatch: { //控制退款是否显示
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				refundDetails: {},
				// 全选按钮绑定变量
				allChecked: false,
				customerData: [], //用户数组
				customerDialog: false, //选择用户弹窗是否弹出
				chooseCustomer: [], //选中的客户
				fOrderIDs: [], //批量操作的订单id
                getDelaystatus: false,
				objList:[],
				manyDialog:false, //控制批量的弹窗
				DeliveryOrderList: [], // 派车单列表
				showDeliveryOrderList: false,
				fOrderID: '',
				showloading: false,
				showList: false
			};
		},
		created() {},
		mounted() {
			console.log(this.getUserInfo)
			console.log(this.value)
			this.getCustomerUsers();
		},
		activited() {},
		update() {},
		beforeRouteUpdate() {},
		computed: {
			...mapGetters(['getUserInfo'])
		},
		methods: {
			// 继续退款
			gorefund() {
				this.showloading = true
				this.ApiRequestPostNOMess("api/mall/ebsale/refund-bill/computer-refund-bill-by-order-id", {
					fOrderID: this.fOrderID,
					fAppTypeID: this.fAppTypeID
				}).then(res => {
					if (res.message == "") {
						this.$store.commit("changeCommonData", {
							id: this.fOrderID
						});
						this.$router.push({
							name: "RefundApplication",
							query: {
								id: this.fOrderID
							}
						})
						this.showloading = false
						this.showDeliveryOrderList=false
					} else {
						this.showloading = false
						this.tipsInfo(res.message)
					}
				})
			},
			// 作废
			deleteOrder(item) {
				console.log(item)
				// fDeliveryOrderID  派车订单ID
				this.$confirm('确定要作废此派车单吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';
							setTimeout(() => {
								done();
								setTimeout(() => {
									instance.confirmButtonLoading = false;
								}, 300);
							}, 1000);
						} else {
							done();
						}
					}
				}).then(() => {
					let index = this.DeliveryOrderList.findIndex(item1 => {
						return item1.fDeliveryOrderID  === item.fDeliveryOrderID
					})
					console.log(index)
					// return
					this.ApiRequestPost("api/mall/ebsale/deliver-order/is-delete", {
						id: item.fDeliveryOrderID.toString()
					}).then(res => {
						this.DeliveryOrderList[index].fIsDeleteName = "已作废"
						this.DeliveryOrderList[index].fIsDelete = "1"
						this.$emit("queryList")
					}).catch(err => {
						this.$emit("queryList")
					})
				}).catch(() => {});
			},
			// 获取订单派车单列表
			getDeliveryOrderList(fOrderId) {
				this.ApiRequestPost("api/mall/ebsale/deliver-order/get-new-list-by-order-id", {
					fOrderId: fOrderId,
					filter:"",
					// "maxResultCount":5,
					// "skipCount":0
				}).then(
					res => {
						this.showList = false
						console.log('getDeliveryOrderList',res);
						this.DeliveryOrderList = res.obj.items  
						this.DeliveryOrderList.forEach(item=> {
							item.ScheduleVanAmount = item.fScheduleVanAmount + item.fUnitName
							item.fDeliveryAddress = item.fDeliveryAddress.replace(/\\/g, '');
						})
					}).catch(err => {
						this.showList = false
					})
			},
			//获取当前登录人的所有用户
			getCustomerUsers() {
				this.ApiRequestPost("api/mall/ebcustomer/baseinfo/get-customer-users").then(
					res => {
						this.customerData = res;
						console.log('this.customerData', this.customerData);
					}).catch(err => {})
			},
			//批量分配订单打开选择用户弹窗
			batchDistribution() {
				// 获取订单已选数据
				for (let item of this.value) {
					if (item.checked == true) {
						this.fOrderIDs.push(item.fOrderID);
					}
				}
				if (this.fOrderIDs.length < 1) {
					this.tipsInfo("请选择要批量分配的订单");
					return;
				}
				this.customerDialog = true;
			},
			//单个分配订单
			distributionClick(val) {
				this.fOrderIDs = [val.fOrderID];
				this.getCustomerDistribution(val.fOrderID);
				this.customerDialog = true;
			},
			getCustomerDistribution(fOrderID) {
				this.ApiRequestPost("api/mall/ebsale/order-users/get-list", {
					fOrderID: fOrderID
				}).then(
					res => {
						console.log('res', res);
						let data = [];
						this.customerData.forEach(item => {
							res.obj.items.forEach(childItem => {
								if (item.fUserId == childItem.fUserID) {
									data.push(item);
								}
							});
						});
						console.log('data', data);
						this.toggleSelection(data);
					}).catch(err => {})
			},
			//默认选中上次选中的用户
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						console.log('121231231');
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			// 全选
			handleSelectionAll(val) {
				this.chooseCustomer = val
			},
			// 选择
			handleSelectionChange(val) {
				this.chooseCustomer = val
			},
			//批量分配订单选择客户完成确定事件（可以传空）
			chooseConfirm() {
				let fUserIDs = [];
				this.chooseCustomer.forEach(item => {
					fUserIDs.push(item.fUserId)
				});
				this.ApiRequestPost("api/mall/ebsale/order-users/create", {
					fOrderIDs: this.fOrderIDs,
					fUserIDs: fUserIDs
				}).then(
					res => {
						this.customerDialog = false;
						this.$emit('distributionClose', false);
					}).catch(err => {
					this.customerDialog = false;
					this.$emit('distributionClose', false);
				})
			},
			// 分享订单
			shareOrder() {

			},
			//批量退款
			RefundClick(){
				let refundArry=[];
        let orderIds = []
				// 获取可退款已选数据
				for (let item of this.value) {
					if (item.checked == true) {
						console.log(item,'梁蕊')
            orderIds.push(item.fOrderID)
						refundArry.push({
							id: item.fOrderID,
							fTradingModelID: item.fTradingModelID, //商业类型b2b b2c
							fCompanyID: item.fCompanyID, //店铺类型  企业之类的
							fBillTypeID: item.fBillTypeID,//单据类型
							fOrderNumber:item.fOrderNumber,  //订单号暂时需要的
							fGoodsFullName:item.fGoodsFullName, //商品名称
						})
					}
			    }
				if (orderIds.length < 1) {
					this.tipsInfo("请选择要退款的订单")
					return
				}
				// 判断合并字符串   商业类型(fTradingModelID) + 店铺类型(fCompanyID) + 单据类型(fBillTypeID)
				let checkStr = null;
				let checkData = {
					fTradingModelID: null,
					fCompanyID: null,
					fBillTypeID: null
				}
				let checkData_notSame = {
					fTradingModelID: null,
					fCompanyID: null,
					fBillTypeID: null
				}	
				// 判断字段是否完全一致
				let isSame = true	
                for (let index = 0; index < refundArry.length; index++) {
                	const item = refundArry[index]
                	// 循环内组合字符串
                	let forCheckStr = item.fTradingModelID + item.fCompanyID + item.fBillTypeID + '';
                	if (index == 0) {
                		checkStr = forCheckStr
                		checkData.fTradingModelID = item.fTradingModelID
                		checkData.fCompanyID = item.fCompanyID
                		checkData.fBillTypeID = item.fBillTypeID
                		checkData_notSame.fTradingModelID = item.fTradingModelID
                		checkData_notSame.fCompanyID = item.fCompanyID
                		checkData_notSame.fBillTypeID = item.fBillTypeID
                	} else {
                		if (forCheckStr == checkStr) {
                			checkData.fTradingModelID = item.fTradingModelID
                			checkData.fCompanyID = item.fCompanyID
                			checkData.fBillTypeID = item.fBillTypeID
                		} else {
                			checkData_notSame.fTradingModelID = item.fTradingModelID
                			checkData_notSame.fCompanyID = item.fCompanyID
                			checkData_notSame.fBillTypeID = item.fBillTypeID
                			isSame = false
                			break;
                		}
                	}
                }
				// //判断是否商业类型不一致
				// if (refundArry.length > 0 && checkData.fTradingModelID != checkData_notSame.fTradingModelID) {
				// 	this.tipsInfo("亲，请不要同时选择不同交易模式的订单");
				// 	return;
				// }
				// 判断是否店铺不一致
				// if (refundArry.length > 0 && checkData.fCompanyID != checkData_notSame.fCompanyID) {
				// 	this.tipsInfo("亲，请不要同时选择不同店铺的订单");
				// 	return;
				// }
        this.$router.push({
          name: "AllRefundApplication",
        })
        this.$store.commit("changeCommonData", orderIds);
				// console.log(refundArry)
				// this.objList=[]
				// for (let i of refundArry) {
				// 	this.ApiRequestPostNOMess("api/mall/ebsale/refund-bill/computer-refund-bill-by-order-id", {
				// 		fOrderID: i.id,
				// 		fAppTypeID: this.fAppTypeID
				// 	}).then(res => {
				// 		console.log(res)
				// 		if(res.obj ==null){
				// 			
				// 			i.message=res.message
				// 			this.objList.push(i)
				// 			this.manyDialog=true
				// 		}
				// 		console.log(this.objList)
				// 		
				// 		if(this.objList.length ==0){
				// 				
				// 			  	this.$router.push({
				// 			  		name: "AllRefundApplication",
				// 			  		query: {
				// 			  			id: i.id
				// 			  		}
				// 			  	})
				// 				this.$store.commit("changeCommonData", refundArry);
				// 		  }
						// if (res.message == "") {
						// 	this.$store.commit("changeCommonData", refundArry);
						// 	this.$router.push({
						// 		name: "RefundApplication",
						// 		query: {
						// 			id: i.id
						// 		}
						// 	})
						// } else {
						// 	this.tipsInfo(res.message)
						// }
			// 		})	
			// 	}
			},
			// 批量支付
			batchPay() {
				// 订单已选数据
				let orderParamArray = []
				let seckillList=[]
				// 获取订单已选数据
				for (let item of this.value) {
					if (item.checked == true) {
						orderParamArray.push({
							id: item.fOrderID,
							fTradingModelID: item.fTradingModelID,
							fCompanyID: item.fCompanyID,
							fBillTypeID: item.fBillTypeID
						})
						seckillList.push({
							fActivityID:item.fActivityID
						})
					}
				}
				if (orderParamArray.length < 1) {
					this.tipsInfo("请选择要支付的订单")
					return
				}
				var seckillShow = seckillList.some(function(x) {
				 return x.fActivityID == 47;
				});
				if(seckillShow == true){
					this.tipsInfo("亲，秒杀活动不支持批量支付");
					return
				}
				// 判断合并字符串   商业类型(fTradingModelID) + 店铺类型(fCompanyID) + 单据类型(fBillTypeID)
				let checkStr = null;
				let checkData = {
					fTradingModelID: null,
					fCompanyID: null,
					fBillTypeID: null
				}
				let checkData_notSame = {
					fTradingModelID: null,
					fCompanyID: null,
					fBillTypeID: null
				}
				// 判断字段是否完全一致
				let isSame = true
				for (let index = 0; index < orderParamArray.length; index++) {
					const item = orderParamArray[index]
					// 循环内组合字符串
					let forCheckStr = item.fTradingModelID + item.fCompanyID + item.fBillTypeID + '';
					if (index == 0) {
						checkStr = forCheckStr
						checkData.fTradingModelID = item.fTradingModelID
						checkData.fCompanyID = item.fCompanyID
						checkData.fBillTypeID = item.fBillTypeID
						checkData_notSame.fTradingModelID = item.fTradingModelID
						checkData_notSame.fCompanyID = item.fCompanyID
						checkData_notSame.fBillTypeID = item.fBillTypeID
					} else {
						if (forCheckStr == checkStr) {
							checkData.fTradingModelID = item.fTradingModelID
							checkData.fCompanyID = item.fCompanyID
							checkData.fBillTypeID = item.fBillTypeID
						} else {
							checkData_notSame.fTradingModelID = item.fTradingModelID
							checkData_notSame.fCompanyID = item.fCompanyID
							checkData_notSame.fBillTypeID = item.fBillTypeID
							isSame = false
							break;
						}
					}
				}
				// 判断是否商业类型不一致
				if (orderParamArray.length > 0 && checkData.fTradingModelID != checkData_notSame.fTradingModelID) {
					this.tipsInfo("亲，请不要同时支付不同交易模式的订单");
					return;
				}
				// 判断是否店铺不一致
				if (orderParamArray.length > 0 && checkData.fCompanyID != checkData_notSame.fCompanyID) {
					this.tipsInfo("亲，请不要同时支付不同店铺的订单");
					return;
				}
				// 判断是否单据类型不一致 TODO  提示
				// if (orderParamArray.length > 0 && checkData.fBillTypeID != checkData_notSame.fBillTypeID) {
				// 	this.tipsInfo("  ");
				// 	return;
				// }
				this.$store.commit("changeCommonData", orderParamArray);
				if (checkData.fTradingModelID.toString() == "2") {
					this.$router.push({
						name: "FillOrderEditB2B",
						params: {}
					})
				} else {
					this.$router.push({
						name: "FillOrderEdit",
						params: {}
					})
				}
			},
			// 批量作废
			batchVoid() {
				// 订单已选数据
				let orderParamArray = []
				// 获取订单已选数据
				for (let item of this.value) {
					if (item.checked == true) {
						if (item.fEnableDeleteStatus == 0) {
							this.tipsInfo("请选择可作废订单进行批量作废")
							return
						}
						orderParamArray.push({
							fOrderID: item.fOrderID
						})
					}
				}
				if (orderParamArray.length < 1) {
					this.tipsInfo("请选择要作废的订单")
					return
				}
				this.$confirm('确定要批量作废已选订单吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// 延期调用API方法
					this.ApiRequestPost("api/mall/ebsale/order/delete-batch", orderParamArray).then(
						res => {
							this.$emit("queryList")
						}).catch(err => {
						this.$emit("queryList")
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消批量作废'
					});
				});
			},

			// 订单列表多选按钮 变化事件
			radioChange(e,) {
        console.log(this.value)

				let isAllChecked = true;
				for (let item of this.value) {
					if (item.checked == false) {
						isAllChecked = false;
						break;
					}
				}

				this.allChecked = isAllChecked
			},
			// 订单列表 全选按钮 变化事件
			allCheckChange() {
				if (this.allChecked == true) {
					this.value.forEach(item => {
						item.checked = true;
					})
				} else {
					this.value.forEach(item => {
						item.checked = false;
					})
				}
			},
			// 详情
			toOrderDetails(item) {
				this.$store.commit("changeCommonData", {
					id: item.fOrderID
				});
				this.$router.push({
					name: "OrderDetails",
					query: {
						id: item.fOrderID,
						detailspath: this.$route.path,
						detailsname: this.$route.meta.title
					}
				});
			},
			// 派车
			toSendCar(item) {
				// fOrderID  订单ID
				this.$store.commit("changeCommonData", {
					id: item.fOrderID
				});
				this.$router.push({
					name: "SendCar",
					query: {
						id: item.fOrderID,
						detailspath: this.$route.path,
						detailsname: this.$route.meta.title
					}
				});
			},
			//退款详情
			toRefundDetails(item) {
				this.ApiRequestPostNOMess("api/mall/ebsale/refund-bill/get-by-order-id", {
					orderId: item.fOrderID
				}).then(res => {
					this.refundDetails = res.obj;
					this.refundDetails.fCreateTime = this.getTime(this.refundDetails.fCreateTime);
				}).catch(err => {

				})
			},
			//去支付
			toPayment(item) {
				this.$store.commit("changeCommonData", [{
					id: item.fOrderID
				}]);
				if (item.fTradingModelID == "2") {
					this.$router.push({
						name: "FillOrderEditB2B",
						params: {}
					})
				} else if(item.fActivityID == "43"){
					this.$router.push({
						name: "createTaskOrder",
						query:{
							integralNumber:item.fUsePoint,
							id:item.fOrderID
						}
					})
				}else if(item.fActivityID == "47"){
					this.$router.push({
						name: "seckillFillOrderEdit",
						query:{
							integralNumber:item.fUsePoint,
							id:item.fOrderID
						}
					})
				}
				else {
					this.$router.push({
						name: "FillOrderEdit",
						params: {}
					})
				}
			},
			toVoid(item) {
				this.$confirm('确定要作废此订单吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.ApiRequestPost("api/mall/ebsale/order/delete-batch", [{
						fOrderID: item.fOrderID
					}]).then(res => {
						this.$emit("queryList")
					}).catch(err => {
						this.$emit("queryList")
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消作废'
					});
				});
			},
			//申请退款
			applyForRefund(item) {
				this.ApiRequestPostNOMess("api/mall/ebsale/refund-bill/computer-refund-bill-by-order-id-isok", {
					fOrderID: item.fOrderID,
					fAppTypeID: this.fAppTypeID
				}).then(res => {
					if (res.obj.fIsOk == 1) {
						this.$store.commit("changeCommonData", {
							id: item.fOrderID
						});
						this.$router.push({
							name: "RefundApplication",
							query: {
								id: item.fOrderID,
								detailspath: this.$route.path,
								detailsname: this.$route.meta.title
							}
						})
					} else {
						this.showDeliveryOrderList=true
						this.showList = true
						this.fOrderID = item.fOrderID,
						this.getDeliveryOrderList(item.fOrderID)
						// this.tipsInfo(res.message)
					}
				})
			},
			//评价
			toEvaluate(item) {
				this.$store.commit("changeCommonData", {
					id: item.fOrderID,
					goodsID: item.fGoodsID
				});
				this.$router.push({
					name: "Evaluate",
					query: {
						detailspath: this.$route.path,
						detailsname: this.$route.meta.title
					}
				})
			},
			// 延期
			delay(item) {
				this.$confirm('确定要延期此订单吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// 延期调用API方法
          this.getDelaystatus = true
					this.ApiRequestPost("api/mall/ebsale/order-delay-bill/create", {
						fOrderID: item.fOrderID
					}).then(res => {
            this.getDelaystatus = false
						this.$emit("queryList")
					}).catch(err => {
						this.$emit("queryList")
            this.getDelaystatus = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消延期'
					});
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			background-color: themed("text_color_white");
			border: 1px solid themed("themes_color");
			color: themed("themes_color");
		}
	}

	.border-color-theme_2 {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.oder-item-btn-box {
		width: 891px;
		background: #F8F9FE;
	}

	.oder-item-btn {
		width: 120px;
		height: 40px;
	}

	.el-radio {
		margin-right: 0;
	}

	.label-bg {
		// width: 56px;
		background: linear-gradient(to right, #ff0000, #ff9999);
	}

	.bg-40C6A9 {
		background-color: #40C6A9;
	}

	.font-color-FF0033 {
		color: #ff0033;
	}

	.item {
		width: 921px;
		margin: 10px 0;
	}

	.item-title {
		box-sizing: border-box;
		width: 921px;
		height: 35px;
		line-height: 35px;
		background: #f2f4fd;

		.enterprise {
			width: 18px;
			height: 18px;
			line-height: 18px;
			border-radius: 18px;
			margin: auto 0;
			margin-left: 5px;
		}
		.newenterprise {
			width: 36px;
			height: 18px;
			line-height: 18px;
			border-radius: 18px;
			margin: auto 0;
			margin-left: 5px;
		}
      .enterpriseBtn{
		  width: 52px;
		  height: 18px;
		  line-height: 18px;
		  border-radius: 18px;
		  margin: auto 0;
		  margin-left: 5px;
	  }
		.orderID {
			width: 165px;
			margin-right: 10px;
			margin-left: 5px;
		}

		.shopname {
			width: 290px;
			margin-left: 40px;

			.icon-dianpu:before {
				font-size: 14px;
			}

			.margin-l-5 {
				margin-left: 5px;
			}
		}

		.endTime {
			margin-right: 10px;
		}

		.share {
			.icon-fenxiang:before {
				font-size: 18px;
			}
		}
	}

	.item-content {
		padding: 13px 0;
		border: 1px solid #f2f4fd;

		.item-content-left {
			flex: 1;
			padding: 12px 0;
		}

		.item-content-right {
			width: 496px;
			display: flex;
		}

		.goods-pic {
			width: 65px;
			height: 65px;
			background: #ffffff;
			border-radius: 10px;
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.156862745098039);
			margin-right: 19px;
			margin-left: 15px;
		}

		.productionPlant {
			height: 16px;
			line-height: 16px;
			margin-bottom: 5px;
		}

		.label {
			height: 16px;
			line-height: 16px;
			padding: 1px 5px;
		}

		.delivery {
			height: 18px;
			line-height: 16px;
			padding: 0 5px;
			box-sizing: border-box;
		}

		.engineering {
			color: #aeaeae;
			margin-top: 5px;
		}

		.evaluated {
			color: #aeaeae;
			margin-top: 3px;
			height: 16px;

			.evaluated-text {
				width: 58px;
			}

			.evaluated-icon {
				height: 10px;
				line-height: 10px;
			}

			ul {
				li:nth-child(n+2) {
					margin-left: 3px;
				}
			}

			.icon-wujiaoxingxingxingshoucangmoren {
				color: #ff3300;
			}

			/deep/ .icon-wujiaoxingxingxingshoucangmoren:before {
				font-size: 8px;
			}
		}

		.num {
			width: 123px;
		}

		.operation {
			button {
				width: 75px;
				height: 24px;
				line-height: 24px;
				border-radius: 2px;
				text-align: center;

				&:nth-child(n + 2) {
					margin-top: 5px;
				}
			}
		}
	}

	.commonBox {
		width: 123px;
		text-align: center;
		border-left: 1px solid #f2f4fd;
		padding: 12px 0;
		box-sizing: border-box;
	}

	.item-bottom {
		margin: 12px 15px 0;
		background: #fff7ee;
		color: #ff9900;
		height: 40px;
		line-height: 40px;

		div {
			flex: 1;
			text-align: center;
		}
	}

	.box {
		height: 80rpx;
		line-height: 80rpx;
	}
	.success-row {
		background-color: #f7f9fe;
	}
	.warning-row {
		background-color: #fff;
	}
</style>
